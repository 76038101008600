<template>
  <v-container fluid class="pt-0">
    <v-row justify="center">
      <v-col cols="12">
        <MainModal
            :main="{ component: 'UserMessageHistory', title: 'Оголошення для користувачів' }"
            :button="{ buttonHidden: true }"
            :item="Object.assign({}, current_item)"
            :modal="show_item_dialog"
            @updateItemModal="updateItemModal"
        />
        <v-card tile elevation="1" class="fill-height" min-height="280" >
          <v-card-title class="pa-0">
            <v-toolbar elevation="0" class="pa-0">
              <v-toolbar-title>
                Список записів оголошень для користувачів
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                  icon
                  class="grey lighten-4"
                  @click="openCreateDialog"
              >
                <v-icon>
                  mdi-plus
                </v-icon>
              </v-btn>
            </v-toolbar>
          </v-card-title>
          <v-data-table
              v-model="selected"
              :headers="tableHeader"
              :items="items"
              no-data-text="Дані відсутні. Жодного запису не виявлено"
              :footer-props="{
                  showFirstLastPage: true,
                  itemsPerPageText: 'Рядків на сторінку',
                  itemsPerPageOptions: [15, 30, -1],

                }"
              class="custom-table custom-table-1"
              @click:row="openComponentModal"
              @item-selected="selectRow"
          >
            <template v-slot:item.icon="{ item }">
              <v-icon size="18" :color="getMessageColor(item)">
                mdi-circle
              </v-icon>
            </template>
            <template v-slot:item.filters="{ item }">
              <v-chip small>
                {{ item.filters === filter_string ? 'Без відбору' : 'З відбором' }}
              </v-chip>
            </template>
            <template v-slot:item.show_end_date="{ item }">
              {{ item.show_end_date | formatDate }}
            </template>
            <template v-slot:item.user_group="{ item }">
              {{ item.user_group === 'all' ? 'Усі користувачі' : 'Користувачі персонал. каб.' }}
            </template>
            <template v-slot:item.posted_date="{ item }">
              {{ item.posted_date | formatDate }}
            </template>
            <template v-slot:item.posted="{ item }">
              <v-simple-checkbox :value="item.posted" :ripple="false"/>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {GET_ALL_USER_MESSAGE_HISTORY} from "@/store/actions/user_message_history";

const DEFAULT_FILTERS_STRING = {
  all_flats: false,
  checker_id: null,
  amalgamated_hromada_id: null,
  city_id: null,
  city_area_id: null,
  streets: [],
  buildings: []
}

export default {
  props: ['selectable', 'model'],
  name: "UserMessageHistoryView",
  components: {
    MainModal: () => import("@/components/modal/MainModal")
  },
  computed: {
    ...mapGetters({
      items: 'getUserMessageHistory'
    })
  },
  data() {
    return {
      selected: [],
      tableHeader: [
        { text: '', value: 'icon', width: 16 },
        { text: 'Заголовок', value: 'title' },
        { text: 'Текст', value: 'message' },
        { text: 'Відбір', value: 'filters', width: 130 },
        { text: 'К-сть', value: 'count', width: 90 },
        { text: 'Група користувачів', value: 'user_group' },
        { text: 'Показувати до', value: 'show_end_date' },
        { text: 'Опубліковано', value: 'posted' },
        { text: 'Дата публікації', value: 'posted_date' },
      ],
      current_item: {},
      show_item_dialog: false,
      filter_string: JSON.stringify(DEFAULT_FILTERS_STRING)
    }
  },
  methods: {
    ...mapActions({
      fetch: GET_ALL_USER_MESSAGE_HISTORY
    }),
    selectRow(payload) {
      this.$emit('selectedItemRow', payload.value ? payload.item : undefined)
    },
    openComponentModal(payload) {
      this.current_item = Object.assign({}, payload)
      this.show_item_dialog = true
    },
    updateItemModal() {
      this.show_item_dialog = false
      this.current_item = {}
    },
    openCreateDialog() {
      this.current_item = {}
      this.show_item_dialog = true
    },
    getMessageColor(payload) {
      switch (payload.message_level) {
        case 1:
          return 'teal'
        case 2:
          return 'success'
        case 3:
          return 'error'
        default:
          return 'grey darken-2'
      }
    }
  },
  watch: {
    model(payload) {
      if (payload) {
        this.selected = []
        this.fetch()
      }
    }
  },
  created() {
    this.fetch()
  }
}
</script>

<style scoped>

</style>